

































































import { Vue, Component, Ref } from 'vue-property-decorator'

import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import DialogoDeEdicaoDePlugin from '@/components/plugin/DialogoDeEdicaoDePlugin.vue'

import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { FormPlugin, Plugin } from '@/models'
import { ambiente } from '@/shareds/utils'

@Component({
	components: {
		DialogoDeEdicaoDePlugin,
		DataTableDeCrud,
	},
})
export default class TelaDePlugins extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDePlugin
	PluginModule = PluginModule
	AlertModule = AlertModule
	loading = false

	cadastrarPlugin() {
		this.dialogoDeEdicao.mostrar({
			nome: '',
			url: '',
			debug: false,
			configs: {},
			ativo: true,
			env: this.getEnv(),
			configsPorLoja: [],
		})
	}

	editarPlugin(indice: number) {
		const plugin = PluginModule.plugins[indice]
		this.dialogoDeEdicao.mostrar({
			id: plugin.id,
			nome: plugin.nome,
			url: plugin.url,
			debug: plugin.debug,
			configs: { ...plugin.configs },
			ativo: plugin.ativo,
			env: plugin.env,
			configsPorLoja: plugin.configsPorLoja,
		})
	}

	async gravarPlugin(plugin: FormPlugin) {
		PluginModule.gravarPlugin(plugin)
		console.log(plugin)
	}

	excluirPlugin(indice: number) {
		const plugin = PluginModule.plugins[indice]
		PluginModule.excluirPlugin(plugin.id)
	}

	abrirDialog(plugin: Plugin) {
		plugin.abrirDialog()
	}

	pingarPlugin(plugin: Plugin) {
		plugin.log(`PING enviado`)
		plugin.emit('ping')
	}

	async testarFuncaoAssincrona(plugin: Plugin) {
		try {
			this.loading = true
			const a = Math.floor(Math.random() * 10)
			const b = Math.floor(Math.random() * 10)
			const result = await plugin.asyncMessage('testeFuncaoAsync', [a, b])
			AlertModule.setSuccess('Resultado de soma aleatórioa: ' + result)
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.loading = false
		}
	}

	created() {
		PluginModule.on('ping', () => PluginModule.emit('pong'))
		PluginModule.on('pong', () => console.log('PONG recebido'))
	}

	destroyed() {
		PluginModule.removeListener('ping')
		PluginModule.removeListener('pong')
	}

	getEnv() {
		switch (ambiente) {
			case 'Produção':
				return 'production'
			case 'Staging':
				return 'staging'
			case '': {
				if (process.env.NODE_ENV === 'production') {
					return 'staging'
				}
				return 'staging'
			}
			default:
				return 'staging'
		}
	}
}
